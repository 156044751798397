import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpClient } from '@angular/common/http';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { UserService } from '../shared/services/user.service';
import { UserAuthService } from '../shared/services/auth/user-auth.service';
import { Component, OnInit, OnDestroy, SimpleChanges, HostListener, OnChanges, Input, EventEmitter, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators, ReactiveFormsModule, NgForm } from '@angular/forms';
import { CreditCardValidators } from 'angular-cc-library';
import { AcceptJSService, CreditCard } from '@openutility/acceptjs-angular-wrapper';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Subscription, timer } from 'rxjs';
import { delay, isEmpty, tap } from 'rxjs/operators';
import { REPL_MODE_SLOPPY } from 'repl';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { TelesignService } from '../shared/services/telesign.service';
import { throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { libphonenumber, PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import { CommonHttpClient } from '../shared/services/common/common-http.service';
import { ChangeDetectorRef } from '@angular/core';

declare function viewPassword(): any;
declare function viewCPassword(): any;
declare function searchFunction(): any;
declare function sortTableExpDate(): any;
declare function sortTableDateAdded(): any;

@Component({
  selector: 'ngbd-modal-content',
  template: `
		<div class="modal-header">
			<h4 style="color:black" class="modal-title">Notice</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
		<div class="modal-body">
			<h5 style="color:black">Plan was successfully updated!</h5>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-blue submitBtn" (click)="activeModal.close('Close click')">OK</button>
		</div>
	`,
})
export class NgbdModalContent {
  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css'],
  animations: [
    trigger(
      'slideView',
      [
        state('true', style({ transform: 'translateX(100%)', opacity: 0 })),
        state('false', style({ transform: 'translateX(0)', opacity: 1 })),
        transition('0 => 1', animate('500ms', style({ transform: 'translateX(0)', 'opacity': 1 }))),
        transition('1 => 1', animate('500ms', style({ transform: 'translateX(100%)', 'opacity': 0 }))),
      ]),

    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('600ms ease-in', style({ transform: 'translateX(0%)', 'opacity': 1 }))
      ]),

      transition(':leave', [
        style({ transform: 'translateX(0%)', opacity: 1 }),
        animate('0ms ease-in', style({ transform: 'translateX(100%)', 'opacity': 0 }))
      ])
    ])
  ]
})
export class PlansComponent implements OnInit {

  checkConfirm: boolean = false;
  FAMID: string;
  emailOTP: boolean = false;
  phoneOTP: boolean = false;
  ConfirmationToken: string;
  ProvisioningToken: string;
  OTPcounter: number = 0;
  emailOrPhoneExists: boolean;
  phoneCheck = "checked";
  emailCheck: string;
  phoneOption: boolean = false;
  response: string;
  defaultSignInAction = 'Phone';
  signinAction = [{ id: '1', value: 'Phone' }, { id: '2', value: 'Email' }]
  billing_amount: number;
  end_date: Date;
  subscription_id: string;
  error: string; success: string;
  strMessage: string;
  passwordNotMatchError: string;
  userForm: FormGroup;
  planForm: FormGroup;
  signSubmit: boolean = false;
  ccSubmit: boolean = false
  creditCardForm: FormGroup;
  redirected: boolean = false;
  steps: number;
  hasChange: boolean;
  currentPromoCodeId: string;
  updated_price: number;
  ShowThankyouScreen: boolean = false;
  currentRateAmount: number
  newPlanRate: number
  windowRef: any;
  recaptchaVerifier: any;
  userToDelete: any = null;
  //variables for int number 
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.India];
  deleteUserPop: boolean = false;
  phone_number = '';
  email = '';
  first_name = '';
  last_name = '';
  display_name = '';
  seats: string;
  plan: string;
  planOption: string;
  password: string;
  confirm_password: string;
  closeResult = '';
  planName: string;
  buttonTextForUpgrade: string;
  //to Display otp input
  showOTP: boolean = false; //this should false when init
  addUser: boolean = false; //change to false
  payment: boolean = false; //change to false
  startSection: boolean = true; // change to true
  upgradePlanCheck: boolean = false;
  showResendOTP: boolean = false // to show resend otp button -- should be false when init
  trans_id: string;
  rate_id: string;
  plan_id: string;
  newPlanPrice: number;
  //for otp timer
  countDown: Subscription;
  counter = 120;
  tick = 1000;
  //for add user record 
  editRecordId = null;
  formData = [];
  user = {
    id: "",
    username: "",
    email: "",
    phoneNumber: "",
    date: ""
  }
  submitted = true;
  remainingSeats: number;
  usedSeatCounter = 0;
  pageSizes: number[] = [5, 10, 20, 50, 100];
  isSubmitBtnDisabled = false;
  individualPlan: boolean = false;
  familyOrSmallOfficePlan: boolean = false;
  smallBusinessPlan: boolean = false;
  midSizeBusinessPlan: boolean = false;
  enterprisePlan: boolean = false;
  additionalSeats: boolean = false;
  disableButton: boolean = false;
  errorSeats: string;
  errorExpDays: string;
  pricePerAdditionalSeat = 0;
  totalSeatsCount = 0;
  finalPrice = 0;
  planSelected: string;
  ratePrice: number;
  rateId: string
  totalSeats: number;
  dateAdded: Date;
  dateToExpire: Date;
  addUserPop: boolean;
  totalSeatsGET: number = 0;
  main_user_id: any;
  main_user_display_name: any;
  paginatedData: any[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 50;
  totalPages: number = 0;
  seatsTakenCount: number = 0;
  editUser: boolean = false;
  PNF: any;
  phoneUtil: any;
  billingDate: number;
  pricePerSeat: number = 0;
  country_code: string;
  ind_check: boolean = false;
  second_check: boolean = false;
  third_check: boolean = false;
  fourth_check: boolean = false;
  fifth_check: boolean = false;
  trial_occurrences: number;

  constructor(private _location: Location, private router: Router, private ngxloader: NgxUiLoaderService,
    private _acceptJSSrv: AcceptJSService, private _http: HttpClient, private route: ActivatedRoute, private ccService: NgcCookieConsentService,
    private modalService: NgbModal, private userService: UserService, private userAuth: UserAuthService,
    private commonHttpInit: CommonHttpClient, private cdr: ChangeDetectorRef) {
    this.initUserForm();
    this.initCommonHTTP();
    this.OnChanges();
    this.PNF = require('google-libphonenumber').PhoneNumberFormat;
    this.phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
  }

  ngOnInit(): void {
    this.ccService.destroy();
    setTimeout(() => {
      this.OnChanges(), 5000
    });
  }
  async initCommonHTTP() {
    this.ngxloader.start();
    var key = await this.commonHttpInit.getApiKey()
    if (key) {
      this.getSubscriptionId();
    }
  }
  setPlanOptions(planName: string): void {
    switch (planName) {
      case "Dependant":
        this.planForm.get('plan_option')?.setValue("Individual");
        this.ind_check = true;
        this.pricePerSeat = 6;
        this.buttonTextForUpgrade = "Change plan";
        break;
      case "Individual":
        this.planForm.get('plan_option')?.setValue("Individual");
        this.ind_check = true;
        this.pricePerSeat = 6;
        this.buttonTextForUpgrade = "Change plan";
        break;
      case "2-4 users":
        this.planForm.get('plan_option')?.setValue("2-4 users");
        this.ind_check = true;
        this.second_check = true;
        this.pricePerSeat = 5;
        if (this.formData.length === 0) {
          this.ind_check = false;
        }
        this.buttonTextForUpgrade = "Change plan";
        break;
      case "5-50 users":
        this.planForm.get('plan_option')?.setValue("5-50 users");
        this.ind_check = true;
        this.second_check = true;
        this.third_check = true;
        this.pricePerSeat = 4;
        if (this.formData.length < 5) {
          this.second_check = false;
        }
        if (this.formData.length === 0) {
          this.ind_check = false;
        }

        this.buttonTextForUpgrade = "Change plan";
        break;
      case "51-300 users":
        this.planForm.get('plan_option')?.setValue("51-300 users");
        this.ind_check = true;
        this.second_check = true;
        this.third_check = true;
        this.fourth_check = true;
        this.pricePerSeat = 3;
        if (this.formData.length < 51) {
          this.third_check = false;
        }
        if (this.formData.length < 5) {
          this.second_check = false;
        }
        if (this.formData.length === 0) {
          this.ind_check = false;
        }
        this.buttonTextForUpgrade = "Change plan";
        break;
      case "301-unlimited users":
        this.planForm.get('plan_option')?.setValue("301-unlimited users");
        this.ind_check = true;
        this.second_check = true;
        this.third_check = true;
        this.fourth_check = true;
        this.fifth_check = true;
        this.pricePerSeat = 2;
        if (this.formData.length < 301) {
          this.fourth_check = false;
        }
        if (this.formData.length < 51) {
          this.third_check = false;
        }
        if (this.formData.length < 5) {
          this.second_check = false;
        }
        if (this.formData.length === 0) {
          this.ind_check = false;
        }
        this.buttonTextForUpgrade = "Change plan";
        break;
      default:
        this.planForm.get('plan_option')?.setValue("");
        this.buttonTextForUpgrade = "Select plan";
        break;
    }
  }
  get f() {
    return this.userForm.controls;
  }

  get ccf() {
    return this.creditCardForm.controls;
  }

  get fp() {
    return this.planForm.controls;
  }

  initUserForm() {
    this.userForm = new FormGroup({
      id: new FormControl('', Validators.maxLength(40)),
      first_name: new FormControl('', [Validators.required, Validators.maxLength(40)]),
      last_name: new FormControl('', [Validators.required, Validators.maxLength(40)]),
      display_name: new FormControl('', [Validators.maxLength(40)]),
      email_id: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/), Validators.maxLength(254)]),
      phone_number: new FormControl('', [Validators.maxLength(50), Validators.minLength(3)]),
      expiry_date_days: new FormControl(''),
      date_added: new FormControl(''),
      date_expire: new FormControl(''),
      is_active: new FormControl('')

    }, { validators: [this.expDaysValidatior] });

    //additional_seats are for Enterprise plan only
    this.planForm = new FormGroup({
      plan_option: new FormControl('', [Validators.required]),
      number_seats: new FormControl('', [Validators.required]),
      additional_seats: new FormControl('', [Validators.maxLength(3)])
    }, { validators: [this.seatsValidatior] });

    this.creditCardForm = new FormGroup({
      fullName: new FormControl('', [Validators.maxLength(200), Validators.minLength(3)]),
      cardNumber: new FormControl('', [CreditCardValidators.validateCCNumber, Validators.maxLength(20)]),
      month: new FormControl('', []),
      year: new FormControl('', []),
      cardCode: new FormControl('', [, Validators.minLength(3), Validators.maxLength(4)]),
      zip: new FormControl('', [Validators.minLength(3), Validators.maxLength(15)]),
    });
  }
  passwordMatchingValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = password;

    if (password.value != '' && confirmPassword.value != '' && password.value != null && confirmPassword.value != null) {
      var pattern = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`#^()_={}|:;"'<,>.])[A-Za-z\d@$!%*?&~`#^()_={}|:;"'<,>.]{8,}$/);
      let currentPassword = password.value;
      var result = currentPassword.match(pattern);
    }
    if (password?.value === confirmPassword?.value ? null : { notmatched: true }) {
      this.passwordNotMatchError = "Password does not match!"
    } else {
      this.clearPasswordNotMatchError();
    }
    if (password.value === confirmPassword.value && password.value != null && confirmPassword.value != null) {
      if (!result) {
        this.passwordNotMatchError = "Password must be at least eight characters, with one uppercase letter, one lowercase letter, one number and one special character."
      } else {
        this.clearPasswordNotMatchError();
      }
    }
    return password?.value === confirmPassword?.value ? null : { notmatched: true };
  };

  backClicked() {
    if (this.router.navigated) {
      this._location.back();
    }

  }

  upgradePlan() {
    this.upgradePlanCheck = true;
    this.addUser = false;
    this.startSection = false;
    this.OnChanges();
    //this.planOption = this.planForm.get('plan_option').value;
    //this.seats = this.planForm.get('number_seats').value;
  }

  paymentScreen() {

    this.planSelected = this.planForm.get('plan_option').value;

    //get plan price
    this.userService.getRateName(this.planSelected).subscribe(resp => {
      if (resp != null) {
        this.ratePrice = resp.Amount;
        this.rateId = resp._id;

        switch (this.planForm.get('plan_option').value) {
          case "Individual":
            this.totalSeatsCount = 0;
            this.newPlanPrice = this.ratePrice;
            break;
          case "2-4 users":
            var familySeatsPackage = 2;
            this.pricePerAdditionalSeat = 5;
            this.totalSeatsCount = Math.abs(this.planForm.get('number_seats').value);
            this.finalPrice = this.totalSeatsCount * this.pricePerAdditionalSeat;
            this.newPlanPrice = Math.round(this.finalPrice * 100) / 100
            break;
          case "5-50 users":
            var smallBusinessSeatsPackage = 5;
            this.pricePerAdditionalSeat = 4;
            this.totalSeatsCount = Math.abs(this.planForm.get('number_seats').value);
            this.finalPrice = this.totalSeatsCount * this.pricePerAdditionalSeat;
            this.newPlanPrice = Math.round(this.finalPrice * 100) / 100
            break;
          case "51-300 users":
            this.pricePerAdditionalSeat = 3;
            if (this.planForm.get('additional_seats').value > 0) {
              this.totalSeatsCount = this.planForm.get('additional_seats').value + Math.abs(this.planForm.get('number_seats').value);
            } else {
              this.totalSeatsCount = 0;
            }
            this.finalPrice = this.totalSeatsCount * this.pricePerAdditionalSeat;
            this.newPlanPrice = Math.round(this.finalPrice * 100) / 100
            break;
          case "301-unlimited users":
            this.pricePerAdditionalSeat = 2;
            if (this.planForm.get('additional_seats').value > 0) {
              this.totalSeatsCount = this.planForm.get('additional_seats').value + Math.abs(this.planForm.get('number_seats').value);;
            } else {
              this.totalSeatsCount = 0;
            }
            this.finalPrice = this.totalSeatsCount * this.pricePerAdditionalSeat;
            this.newPlanPrice = Math.round(this.finalPrice * 100) / 100
            break;
          default:

            break;
        }

        this.upgradePlanCheck = false;
        if (this.planName != "Dependant") {
          this.onSubmit();
        } else {
          this.upgradePlanCheck = false
          this.payment = true;
        }
      }
    }, error => {
      this.error = 'Error getting plan price!'
    });

  }
  clearInfoMessages() {
    this.error = null;
    this.success = null;
  }

  clearPasswordNotMatchError() {
    this.passwordNotMatchError = null;
  }

  //make the cc form to change the payment card to the new entered one and amount by family static fee or professional number of seats fee
  public async onSubmit(): Promise<void> {
    this.ngxloader.start();
    this.error = null;

    if (this.planSelected == '301-unlimited users' || this.planSelected == '51-300 users') {
      this.totalSeats = Number(this.planForm.get('number_seats').value) + Number(this.totalSeatsCount);
    } else {
      if (this.planSelected == 'Individual') {
        this.totalSeats = 0;
      } else {
        this.totalSeats = Number(this.planForm.get('number_seats').value);
      }
    }

    //if (!this.creditCardForm.valid) {
    //  return;
    //}
    let updateMongoData = {
      rate_id: this.rateId,
      seats: this.totalSeats
    }
    //let cc: CreditCard = this.creditCardForm.value;
    //let ccExpDate = cc.year + '-' + cc.month;
    //let ccNumber = cc.cardNumber;
    //let ccCode = cc.cardCode;
    //let zipCode = cc.zip;
    if (localStorage.getItem('0').includes('@')) {
      try {
        if (this.planName != "Dependant") {
          const rply = await this.userService.changePaymentWithEmail({
            transactionId: this.trans_id,
            amount: this.newPlanPrice
          }).toPromise();

          this.strMessage = JSON.stringify(rply);

          if (rply && (rply as any).respCode === 'I00001') {
            //change popup accordingly !!!
            //edit the user: add seats to main user and plan type
            var email = localStorage.getItem('0')
            //this needs to be flexisip request
            this.userService.updateSubscriptionWithEmail(email, updateMongoData).subscribe(resp => {
              if (resp.message == 'success') {
                this.open();
                this.ngxloader.stop();
              }
              else {
                this.error = "Update failed!";
              }
              this.ngxloader.stop();
            }, error => {

              this.ngxloader.stop();
            })
          }

          if (rply != null && rply != 'Invalid OTS Token.') {
            if (rply == "Bill-To Last Name is required.") {
              this.error = 'Last Name is required.'
            }
          }

          if (rply && 'error' in rply) {
            this.error = (rply as any).error.errorText;
          }

          if (rply != null && (rply as any).respCode != 'I00001') {
            this.error = rply;
          }
        } else {
          this.onSubmitDependant();
        }
        
      } catch (ex) {
        if (this.strMessage != '"Invalid OTS Token."') {
          if (ex.messages != null) {
            this.error = ex.messages.message[0].text;
          }
          console.error(this.error);
          this.ngxloader.stop();
        }
      }
    } else {
      //with phone
      try {
        if (this.planName != "Dependant") {
          const rply = await this.userService.changePaymentWithPhone({
            transactionId: this.trans_id,
            amount: this.newPlanPrice
          }).toPromise();

          this.strMessage = JSON.stringify(rply);

          if (rply && (rply as any).respCode === 'I00001') {
            var phone = localStorage.getItem('0')
            this.userService.updateSubscriptionWithPhoneNumber(phone, updateMongoData).subscribe(resp => {
              if (resp.message == 'success') {
                this.open();
                this.ngxloader.stop();
              }
              else {
                this.error = "Update failed!";
              }
              this.ngxloader.stop();
            }, error => {

              this.ngxloader.stop();
            })
          }

          if (rply != null && rply != 'Invalid OTS Token.') {
            if (rply == "Bill-To Last Name is required.") {
              this.error = 'Last Name is required.'
            }
          }

          if (rply && 'error' in rply) {
            this.error = (rply as any).error.errorText;
          }

          if (rply != null && (rply as any).respCode != 'I00001') {
            this.error = rply;
          }
        } else {
          this.onSubmitDependant();
        }

      } catch (ex) {
        if (this.strMessage != '"Invalid OTS Token."') {
          if (ex.messages != null) {
            this.error = ex.messages.message[0].text;
          }
          console.error(this.error);
          this.ngxloader.stop();
        }
      }
    }
  }

  public async onSubmitDependant(): Promise<void> {
    this.error = null;
    let unformattedcardnumber = this.creditCardForm.get('cardNumber').value;
    let formattedcard = this.removeSpaceBetween(unformattedcardnumber);
    this.creditCardForm.value.cardNumber = formattedcard;
    console.log(formattedcard)
    if (!this.creditCardForm.valid) {
      return;
    }
    
    let cc: CreditCard = this.creditCardForm.value;
    this.trial_occurrences = 0;
    if (localStorage.getItem('0').includes('@')) {
      try {
        // generate a nonce payment using the acceptJS service.
        const nonce = await this._acceptJSSrv.generatePaymentNonce(cc);
        await this.delay(6000);
        /*this.ngxloader.stop();*/
        // submit nonce to your server with payment amount
        const rply = await this.userService.payment({
          nonce,
          amount: this.newPlanPrice,
          fullName: this.creditCardForm.value.fullName,
          trial_amount: this.newPlanPrice,
          trial_occurrences: this.trial_occurrences
        }).toPromise();

        this.strMessage = JSON.stringify(rply);
        let jsonResponse = JSON.parse(this.strMessage);

        if (rply == 'Invalid OTS Token.') {
          this.retryPayment();
        }

        if (rply == "Bill-To Last Name is required.") {
          this.error = 'Full Name is required.'
        }

        if (rply != null && rply != 'Invalid OTS Token.' && jsonResponse.transId != null) {
          this.subscription_id = jsonResponse.transId;
          this.billing_amount = jsonResponse.subAmount;
        }

        let updateMongoData = {
          auth_sub_id: this.subscription_id,
          next_billing_amount: this.billing_amount,
          next_billing_date: this.end_date,
          start_billing_date: jsonResponse.dateInterval.startDate,
          is_active: true,
          rate_id: this.rateId,
          promo_code_id: this.currentPromoCodeId,
          display_name: this.creditCardForm.value.fullName,
          trial_usage: true,
          trial_end: this.end_date,
          otp: 0,
          // fam_id: this.FAMID
          //update seats depending on chosen plan
          seats: this.totalSeats,
          main_user_id: "",
          date_expire: null
        }

        if (rply && (rply as any).respCode === 'I00001') {
          //change popup accordingly !!!
          //edit the user: add seats to main user and plan type
          var email = localStorage.getItem('0')
          //this needs to be flexisip request
          this.userService.updateSubscriptionWithEmail(email, updateMongoData).subscribe(resp => {
            if (resp.message == 'success') {
              this.open();
              this.ngxloader.stop();
            }
            else {
              this.error = "Update failed!";
            }
            this.ngxloader.stop();
          }, error => {

            this.ngxloader.stop();
          })
        }

        if (rply != null && rply != 'Invalid OTS Token.' && jsonResponse.transId == null) {
          if (rply == "Bill-To Last Name is required.") {
            this.error = 'Last Name is required.'
          }
          else {
            this.error = rply;
          }
        }

        if (rply && 'error' in rply) {
          this.error = (rply as any).error.errorText;
        }

      } catch (ex) {
        if (this.strMessage != '"Invalid OTS Token."') {
          if (ex.messages != null && ex.messages.message[0].text != null) {
            this.error = ex.messages.message[0].text;
          }
          console.error(this.error);
          this.ngxloader.stop();
        }
      }
    } else {
      //with phone
      try {
        // generate a nonce payment using the acceptJS service.
        const nonce = await this._acceptJSSrv.generatePaymentNonce(cc);
        await this.delay(6000);
        /*this.ngxloader.stop();*/
        // submit nonce to your server with payment amount
        const rply = await this.userService.payment({
          nonce,
          amount: this.newPlanPrice,
          fullName: this.creditCardForm.value.fullName,
          trial_amount: this.newPlanPrice,
          trial_occurrences: this.trial_occurrences
        }).toPromise();

        this.strMessage = JSON.stringify(rply);
        let jsonResponse = JSON.parse(this.strMessage);
        console.log(rply);
        if (rply == 'Invalid OTS Token.') {
          this.retryPayment();
        }

        if (rply == "Bill-To Last Name is required.") {
          this.error = 'Full Name is required.'
        }

        if (rply != null && rply != 'Invalid OTS Token.' && jsonResponse.transId != null) {
          this.subscription_id = jsonResponse.transId;
          this.billing_amount = jsonResponse.subAmount;
        }

        let updateMongoData = {
          auth_sub_id: this.subscription_id,
          next_billing_amount: this.billing_amount,
          next_billing_date: this.end_date,
          start_billing_date: jsonResponse.dateInterval.startDate,
          is_active: true,
          rate_id: this.rateId,
          promo_code_id: this.currentPromoCodeId,
          display_name: this.creditCardForm.value.fullName,
          trial_usage: true,
          trial_end: this.end_date,
          otp: 0,
          // fam_id: this.FAMID
          //update seats depending on chosen plan
          seats: this.totalSeats,
          main_user_id: "",
          date_expire: null
        }

        if (rply && (rply as any).respCode === 'I00001') {
          //change popup accordingly !!!
          //edit the user: add seats to main user and plan type
          var phone = localStorage.getItem('0')
          //this needs to be flexisip request
          this.userService.updateSubscriptionWithPhoneNumber(phone, updateMongoData).subscribe(resp => {
            if (resp.message == 'success') {
              this.open();
              this.ngxloader.stop();
            }
            else {
              this.error = "Update failed!";
            }
            this.ngxloader.stop();
          }, error => {

            this.ngxloader.stop();
          })
        }

        if (rply != null && rply != 'Invalid OTS Token.' && jsonResponse.transId == null) {
          if (rply == "Bill-To Last Name is required.") {
            this.error = 'Last Name is required.'
          }
          else {
            this.error = rply;
          }
        }

        if (rply && 'error' in rply) {
          this.error = (rply as any).error.errorText;
        }

      } catch (ex) {
        if (this.strMessage != '"Invalid OTS Token."') {
          if (ex.messages != null && ex.messages.message[0].text != null) {
            this.error = ex.messages.message[0].text;
          }
          console.error(this.error);
          this.ngxloader.stop();
        }
      }
    }
  }

  getDependentUsers() {
    if (localStorage.getItem('0') != null) {
      if (localStorage.getItem('0').includes('@')) {
        var email = localStorage.getItem('0')
        this.userService.getDependentUsersEmail(email).subscribe(resp => {
          if (resp != null && resp.message != 'No users found for the given phone number') {
            this.formData = [...resp];
            for (let i = 0; i < this.formData.length; i++) {
              let countryCode = this.formData[i].country_code
              let phoneToConvert = this.formData[i].phone_number.internationalNumber
              if (countryCode != null && phoneToConvert != null) {
                let convertedNumber = this.phoneUtil.parseAndKeepRawInput(phoneToConvert, countryCode)
                var formattedNumber = this.phoneUtil.format(convertedNumber, this.PNF.INTERNATIONAL);
                this.formData[i].phone_number.internationalNumber = formattedNumber;
              }

            }

            if (this.totalSeatsGET - this.formData.length < 1) {
              this.isSubmitBtnDisabled = true;
            }
            this.seatsTakenCount = this.formData.length;
            this.totalPages = Math.ceil(this.formData.length / this.itemsPerPage);
            this.updatePaginatedData();
            this.setPlanOptions(this.planName);
            this.cdr.detectChanges();
          }
        }, error => { this.error = 'Error!' })
      }
    }
    if (!localStorage.getItem('0').includes('@')) {
      var phone = localStorage.getItem('0')
      this.userService.getDependentUsersPhone(phone).subscribe(resp => {
        if (resp != null && resp.message != 'No users found for the given phone number') {
          this.formData = [...resp];
          for (let i = 0; i < this.formData.length; i++) {
            let countryCode = this.formData[i].country_code
            let phoneToConvert = this.formData[i].phone_number.internationalNumber
            if (countryCode != null && phoneToConvert != null) {
              let convertedNumber = this.phoneUtil.parseAndKeepRawInput(phoneToConvert, countryCode)
              var formattedNumber = this.phoneUtil.format(convertedNumber, this.PNF.INTERNATIONAL);
              this.formData[i].phone_number.internationalNumber = formattedNumber;

            }
          }

          if (this.totalSeatsGET - this.formData.length < 1) {
            this.isSubmitBtnDisabled = true;
          }
          this.seatsTakenCount = this.formData.length;
          this.totalPages = Math.ceil(this.formData.length / this.itemsPerPage);
          this.updatePaginatedData();
          this.setPlanOptions(this.planName);
          this.cdr.detectChanges();
        }
      }, error => { this.error = 'Error!' })
    }
  }
  getSubscriptionId() {
    if (localStorage.getItem('0') != null) {
      if (localStorage.getItem('0').includes('@')) {
        var email = localStorage.getItem('0')
        this.userService.getSubscriptionIdWithEmail(email).subscribe(resp => {
          if (resp.message === 'success') {
            /*this.fam_id = resp.fam_id;*/
            this.trans_id = resp.sub_id;
            this.rate_id = resp.rate;
            this.totalSeatsGET = resp.seats;
            this.main_user_id = resp.id;
            this.main_user_display_name = resp.display_name;
            const date = new Date(resp.next_billing_date);
            this.billingDate = date.getUTCDate();
            if (this.rate_id) {
              this.userService.getRateAmount(this.rate_id).subscribe(rateResp => {
                this.plan_id = rateResp.PlanId;
                this.currentRateAmount = rateResp.Amount;
                this.pricePerSeat = rateResp.SeatPrice;
                this.userService.getPlanName(this.plan_id).subscribe(planResp => {
                  this.planName = planResp.Name;
                  if (planResp.Name !== "Free") {
                    this.addUser = true;
                    this.getDependentUsers();
                    setTimeout(() => {
                      sortTableDateAdded(), 1000
                    });
                  } else {
                    this.planName = "Dependant";
                  }
                  this.ngxloader.stop();
                }, error => { })
              }, error => { })
            }
            else {
              this.planName = "Dependant";
              this.ngxloader.stop();
            }
          }
        }, error => { this.error = 'Error!' })
      }
    }
    if (!localStorage.getItem('0').includes('@')) {
      var phone = localStorage.getItem('0')
      this.userService.getSubscriptionIdWithPhone(phone).subscribe(resp => {
        if (resp.message === 'success') {
          /* this.fam_id = resp.fam_id;*/
          this.trans_id = resp.sub_id;
          this.rate_id = resp.rate;
          this.totalSeatsGET = resp.seats;
          this.main_user_id = resp.id;
          this.main_user_display_name = resp.display_name;
          const date = new Date(resp.next_billing_date);
          this.billingDate = date.getUTCDate();
          if (this.rate_id) {
            this.userService.getRateAmount(this.rate_id).subscribe(rateResp => {
              this.plan_id = rateResp.PlanId;
              this.currentRateAmount = rateResp.Amount;
              this.pricePerSeat = rateResp.SeatPrice;
              this.userService.getPlanName(this.plan_id).subscribe(planResp => {
                this.planName = planResp.Name;
                if (planResp.Name !== "Free") {
                  this.addUser = true;
                  this.getDependentUsers();
                  setTimeout(() => {
                    sortTableDateAdded(), 1000
                  });
                } else {
                  this.planName = "Dependant";
                }
                this.ngxloader.stop();
              }, error => { })
            }, error => { })
          }
          else {
            this.planName = "Dependant";
            this.ngxloader.stop();
          }
        } else {
          this.planName = "Dependant";
        }
      }, error => { this.error = 'Error!' })
    }
  }
  open() {
    const modalRef = this.modalService.open(NgbdModalContent).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        window.location.reload();
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        window.location.reload();
      },
    );
  }
  removeSpaceBetween(value) {
    return value.toString().replace(/\s/g, "");
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  OnChanges() {
    this.setPlanOptions(this.planName);
    this.planForm.get('plan_option').valueChanges.subscribe(selectedPlanOption => {
      switch (this.planForm.get('plan_option').value) {
        case "Individual":
          this.individualPlan = true;
          this.familyOrSmallOfficePlan = false;
          this.smallBusinessPlan = false;
          this.midSizeBusinessPlan = false;
          this.enterprisePlan = false;
          this.additionalSeats = false;
          break;
        case "2-4 users":
          this.individualPlan = false;
          this.familyOrSmallOfficePlan = true;
          this.smallBusinessPlan = false;
          this.midSizeBusinessPlan = false;
          this.enterprisePlan = false;
          this.additionalSeats = false;
          break;
        case "5-50 users":
          this.individualPlan = false;
          this.familyOrSmallOfficePlan = false;
          this.smallBusinessPlan = true;
          this.midSizeBusinessPlan = false;
          this.enterprisePlan = false;
          this.additionalSeats = false;
          break;
        case "51-300 users":
          this.individualPlan = false;
          this.familyOrSmallOfficePlan = false;
          this.smallBusinessPlan = false;
          this.midSizeBusinessPlan = true;
          this.enterprisePlan = false;
          this.additionalSeats = true;
          break;
        case "301-unlimited users":
          this.additionalSeats = true;
          this.individualPlan = false;
          this.familyOrSmallOfficePlan = false;
          this.smallBusinessPlan = false;
          this.midSizeBusinessPlan = false;
          this.enterprisePlan = true;
          break;
        default:
          this.individualPlan = false;
          this.familyOrSmallOfficePlan = false;
          this.smallBusinessPlan = false;
          this.midSizeBusinessPlan = false;
          this.enterprisePlan = false;
          this.additionalSeats = false;
          break;
      }
    })
  }

  onEdit(user) {
    // destructure user object separate ID and rest of the fields
    //changes the confirm button on the modal to update the dbs and table
    this.editUser = true;
    user.id = user.date_added;
    user.display_name = user.first_name + " " + user.last_name;
    user.expiry_date_days = null;
    const { ...data } = user
    this.isSubmitBtnDisabled = false;
    // set edit record ID
    this.editRecordId = user.id;
    user.is_active = true;
    // set form value with selected user
    this.userForm.setValue({
      id: data.id,
      first_name: data.first_name,
      last_name: data.last_name,
      display_name: data.display_name,
      email_id: data.email_id,
      phone_number: data.phone_number.internationalNumber,
      expiry_date_days: null,
      date_added: data.date_added,
      date_expire: data.date_expire,
      is_active: true
    });
    //disable phone and email fields
    this.userForm.get('email_id').disable();
    this.userForm.get('phone_number').disable();
    this.addUser = true;
    this.addUserPop = true;
    this.cdr.detectChanges();
  }

  onDelete() {
    if (this.userToDelete) {
    // filter out deleted entry from form data array matching
    // with the ID of deleted user record with ID from form data array
    this.ngxloader.start();
    if (this.totalSeatsGET - this.formData.length >= 1) {
      this.isSubmitBtnDisabled = false;
    }

    this.deleteTempUser(this.userToDelete);

      this.cdr.detectChanges();
  }
  }
  confirmDelete(user: any) {
    this.userToDelete = user;
    this.deleteUserPop = true;
  }
  cancelDelete() {
    this.userToDelete = null;
    this.deleteUserPop = false;
  }
  addUserRecord() {
    this.submitted = true;
    this.dateAdded = new Date();
    this.userForm.get('date_added').setValue(this.dateAdded);
    this.userForm.get('id').setValue(this.dateAdded);
    this.dateToExpire = new Date();

    if (this.userForm.get('phone_number').value.e164Number.includes('+')) {
      let phone = this.removePlusFromstring(this.userForm.get('phone_number').value.e164Number);
      this.userForm.get('phone_number').value.e164Number = phone;
    }

    if (this.userForm.get('expiry_date_days').value > 0) {
      this.dateToExpire.setDate(this.dateToExpire.getDate() + this.userForm.get('expiry_date_days').value);
      this.userForm.get('date_expire').setValue(this.dateToExpire);
    }
    else {
      this.dateToExpire = null;
      this.userForm.get('date_expire').setValue(this.dateToExpire);
    }

    // if (this.planForm.get('plan_option').value == 'Enterprise' || this.planForm.get('plan_option').value == '51-300 users') {
    //   this.remainingSeats = Number(this.planForm.get('number_seats').value) + Number(this.planForm.get('additional_seats').value);
    // } else {
    //   this.remainingSeats = Number(this.planForm.get('number_seats').value);
    // }



    if (this.editRecordId) {
      // check if already exist record in formData matches with the
      // edit reocrd id that means its edited record then return newly
      // submitted form value else return old formData record
      // and populate formData array.
      this.formData = this.formData.map((data) => data.id === this.editRecordId ? this.userForm.value : data)

      // rest edit record id to null again
      this.editRecordId = null;
    } else {
      // assigning unique ID to each record I used timestamp technically it would be database primary key ID
      // const id = Date.now();
      // this.userForm.get('id').setValue(id);
      // const data = {

      //   ...this.userForm.value,
      //   is_active: true
      // }
      // this.formData.push(data)
      this.getDependentUsers();
      if (this.totalSeatsGET - this.formData.length < 1) {
        this.isSubmitBtnDisabled = true;
      }
    }
    this.updatePaginatedData();
    this.cdr.detectChanges();


    this.userForm.reset();
  }
  generateRandomPassword(): string {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const chars2 = '@#$!%^&';
    let password = '';

    // Generate the main part of the password (excluding the special character)
    for (let i = 0; i < 9; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    // Generate a random special character
    const specialChar = chars2.charAt(Math.floor(Math.random() * chars2.length));

    // Insert the special character at a random position in the password
    const randomPosition = Math.floor(Math.random() * (password.length + 1));
    password = password.slice(0, randomPosition) + specialChar + password.slice(randomPosition);

    return password;
  }

  createUser() {
    this.ngxloader.start();
    if (this.userForm.get('phone_number').value?.e164Number) {
      let phone = this.userForm.get('phone_number').value.e164Number;
      this.phone_number = this.removePlusFromstring(phone);
    }
    this.display_name = this.userForm.get('first_name').value + " " + this.userForm.get('last_name').value;


    if (this.userForm.get('phone_number').value.countryCode != null) {
      this.country_code = this.userForm.get('phone_number').value.countryCode;
    }

    this.password = this.generateRandomPassword();

    let createFlexisipUserData = {
      username: "+" + this.phone_number,
      password: this.password,
      algorithm: "SHA-256",
      phone: "+" + this.phone_number,
      email: this.email,
      display_name: this.display_name,
      activated: true
    };

    const createFlexisipUserRequest = this.userService.createFlexisipUser(createFlexisipUserData).pipe(
      catchError(error => {
        this.error = error;
        return throwError(error);
      })
    );

    createFlexisipUserRequest.pipe(
      switchMap((resp: any) => {
        this.FAMID = resp.id;
        this.dateAdded = new Date();
        this.dateToExpire = new Date();

        if (this.userForm.get('expiry_date_days').value > 0) {
          this.dateToExpire.setDate(this.dateToExpire.getDate() + this.userForm.get('expiry_date_days').value);
        }
        else {
          this.dateToExpire = null;
        }
        let createUserData = {
          ...this.userForm.value,
          phone_number: this.phone_number,
          is_active: true,
          password: this.password,
          display_name: this.display_name,
          fam_id: this.FAMID,
          rate_id: "643faa83249ee74859b0c8ca",
          main_user_id: this.main_user_id,
          date_added: this.dateAdded,
          date_expire: this.dateToExpire,
          email_id: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          country_code: this.country_code
        };

        return this.userService.dependentUserCreate(createUserData).pipe(
          catchError(error => {
            this.error = error;
            return throwError(error);
          })
        );
      })
    ).subscribe({
      next: (createUserResponse: any) => {
        if (createUserResponse.message === 'User was registered successfully!') {

          var emailData = {
            to: this.email,
            phone_number: this.phone_number,
            password: this.password,
            from: this.main_user_display_name
          }

          this.userService.sendUserInformation(emailData).subscribe(resp => {
            this.ngxloader.stop();
            this.addUserRecord();

            this.addUserPop = false;
          });

        } else {
          this.error = createUserResponse.message;
        }
      },
      error: (error) => {
        console.log('Error is', error);
        this.error = "An error occurred while creating the user.";
      }
    });
  }
  verifyUserSubscription() {
    this.clearInfoMessages();
    let phone = this.userForm.get('phone_number').value;
    this.email = this.userForm.get('email_id').value;
    this.first_name = this.userForm.get('first_name').value;
    this.last_name = this.userForm.get('last_name').value;
    this.emailOrPhoneExists = false;
    if (phone) {
      this.phone_number = this.removePlusFromstring(phone.e164Number);
      let data = {
        phone_number: this.removePlusFromstring(phone.e164Number)
      }
      this.userService.validateUserPhoneNumber(data).subscribe(resp => {
        this.ngxloader.start();
        if (resp.message === 'success (phone number already exists)') {
          this.response = resp.message;
          this.error = "This number is already registered";
          this.ngxloader.stop();
        }
      }, err => {
        if (this.email) {
          let data = {
            email_id: this.email
          }
          this.userService.validateUserEmailAddress(data).subscribe(resp => {
            this.ngxloader.start();
            if (resp.message === 'success (email already exists)') {
              this.response = resp.message;
              this.error = "This email is already registered";
              this.ngxloader.stop();
              this.emailOrPhoneExists = true;
            }
          }, err => {
            this.createUser();
          });
        }
      });
    }

  }

  async deleteTempUser(user) {
    if (user.email_id != null) {
      var email = user.email_id;
      this.userService.deleteAccountEmailFAM(email).subscribe(resp => {
        if (resp.message === 'Account deleted!') {
          this.userService.deleteAccountEmail(email).subscribe(famResp => {
            if (famResp.message === 'Account deleted!') {
              this.ngxloader.stop();
              this.getDependentUsers();
            } else {
              this.ngxloader.stop();
              this.error = 'Error removing user!'
            }
            this.deleteUserPop = false;
          });

        }
      }, error => { this.error = 'Error removing user!' })
    } else {
      if (user.phone_number != null) {
        var phone = user.phone_number
        this.userService.deleteAccountPhoneFAM(phone).subscribe(resp => {
          if (resp.message === 'Account deleted!') {
            this.userService.deleteAccountPhone(phone).subscribe(famResp => {
              if (famResp.message === 'Account deleted!') {
                this.ngxloader.stop();
                this.getDependentUsers();
              } else {
                this.ngxloader.stop();
                this.error = 'Error unsubscribing!'
              }

            });
          }
        }, error => { this.error = 'Error unsubscribing!' })
      }
    }
  }

  confirmUserEdit() {
    this.userForm.get('email_id').enable();
    this.userForm.get('phone_number').enable();
    if (this.totalSeatsGET - this.formData.length < 1) {
      this.isSubmitBtnDisabled = true;
    }

    if (this.userForm.valid) {
      this.ngxloader.start();
      let firstName = this.userForm.get('first_name').value;
      let lastName = this.userForm.get('last_name').value;
      this.dateToExpire = new Date();

      if (this.userForm.get('expiry_date_days').value > 0) {
        this.dateToExpire.setDate(this.dateToExpire.getDate() + this.userForm.get('expiry_date_days').value);
        this.userForm.get('date_expire').setValue(this.dateToExpire);
      }
      else {
        this.dateToExpire = null;
        this.userForm.get('date_expire').setValue(this.dateToExpire);
      }
      let dateExpire = this.dateToExpire;
      let phoneNumber = this.userForm.get('phone_number').value.e164Number;
      this.phone_number = this.removePlusFromstring(phoneNumber);
      let email = this.userForm.get('email_id').value;

      let updateMongoData = {
        first_name: firstName,
        last_name: lastName,
        date_expire: dateExpire,
        display_name: firstName + " " + lastName
      }
      if (this.email != null) {
        this.userService.updateSubscriptionWithEmail(email, updateMongoData).subscribe(resp => {
          if (resp.message == "success") {
            //fam request
            let updateFamData = {
              // first_name: firstName,
              // last_name: lastName,
              display_name: firstName + " " + lastName
            }
            this.userService.updateTempUserMariaAPIByEmail(email, updateFamData).subscribe(famResp => {
              if (famResp.message === 'Temp user updated successfully!') {
                this.editUser = false;
                this.addUserRecord();
                this.addUserPop = false;
                this.ngxloader.stop();
              }
            });
          }
        }, (error) => { console.log('Error is', error); })
      } else {
        this.userService.updateSubscriptionWithPhoneNumber(this.phone_number, updateMongoData).subscribe(resp => {
          if (resp.message == "success") {
            //fam request
            let updateFamData = {
              // first_name: firstName,
              // last_name: lastName,
              display_name: firstName + " " + lastName
            }
            this.userService.updateTempUserMariaAPIByPhone(this.phone_number, updateFamData).subscribe(famResp => {
              if (famResp.message === 'Temp user updated successfully!') {
                this.editUser = false;
                this.addUserRecord();
                this.addUserPop = false;
                this.ngxloader.stop();
              }
            });
          }
        }, (error) => { console.log('Error is', error); })
      }
    } else {
      this.error = "Please fill up all the required fields with valid details"
      this.ngxloader.stop();
    }
  }

  resendUserInformation(user) {
    this.ngxloader.start();
    var emailData = {
      to: user.email_id,
      from: this.main_user_display_name
    }

    this.userService.sendUserInformation(emailData).subscribe(resp => {
      this.ngxloader.stop();
    });
  }

  closeModal() {
    if (this.totalSeatsGET - this.formData.length < 1) {
      this.isSubmitBtnDisabled = true;
    }
    this.editUser = false;
    this.editRecordId = null;
    this.addUserPop = false;
  }

  openModal() {
    this.addUserPop = true;
    this.userForm.reset();
    this.userForm.get('email_id').enable();
    this.userForm.get('phone_number').enable();
    this.editUser = false;
  }

  removePlusFromstring(phoneNumber) {
    return phoneNumber.split('+').join('')
  }

  scrollTop() {
    window.scrollTo(0, 70);
  }

  seatsValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const currectAdditionalSeats = control.get('additional_seats');

    if (currectAdditionalSeats.value < 0 || currectAdditionalSeats.value > 249 || Number.isNaN(currectAdditionalSeats)) {
      this.errorSeats = "Please enter additional seats from 1 to 249."
      this.disableButton = true;
    } else {
      this.errorSeats = null;
      this.disableButton = false;
    }

    return currectAdditionalSeats?.value;
  };

  expDaysValidatior: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const daysToExpiry = control.get('expiry_date_days');

    if (daysToExpiry.value < 1 || daysToExpiry.value > 90 && daysToExpiry != null) {
      this.errorExpDays = "Please enter the days from 1 to 90 or leave blank for permanent."
    } else {
      this.errorExpDays = null;
    }

    return daysToExpiry?.value;
  };
  updatePaginatedData(): void {
    const formData = this.formData;
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedData = formData.slice(startIndex, endIndex);
    setTimeout(() => {
      sortTableDateAdded();
    }, 1000);
  }

  changePageSize(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.itemsPerPage = +selectElement.value;
    this.currentPage = 1; // Reset to the first page whenever the page size changes
    this.updateTotalPages();
    this.updatePaginatedData();
    this.cdr.detectChanges();
  }

  updateTotalPages(): void {
    this.totalPages = Math.max(1, Math.ceil(this.formData.length / this.itemsPerPage));
  }


  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePaginatedData();
      this.cdr.detectChanges();
    }
  }

  previousPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePaginatedData();
      this.cdr.detectChanges();
    }
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  retryPayment() {
    this.onSubmitDependant();
  }
}
